import React, { Component } from "react";
import { Link } from "react-router-dom";

class Formati extends Component {
  render() {
    return (
      <div className="blog-list-area go-top">
        <div className="container pd-bottom-120">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="section-title text-center">
                <h2 className="title">Formati</h2>

                <p>
                  Naša tvrtka posjeduje širok raspon različitih formata vanjskog
                  oglašavanja, a na ovoj stranici ćete pronaći kategorizaciju
                  svakog pojedinog formata te detaljne informacije o svakom od
                  njih, uz prateće slike. Pregledajte niže kako biste otkrili
                  sve mogućnosti koje nudimo i odabrali najbolju opciju za vaše
                  oglašavanje
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <Link to="/billboards-bigboards">
                <div className="single-destinations-list style-four">
                  <div
                    className="blur-thumb"
                    style={{
                      backgroundImage:
                        "url(https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/13.png?alt=media&token=df77d3fc-7d9a-4494-b3d3-b09e88b39b68)",
                    }}
                  />{" "}
                  <div className="details">
                    <h4 className="title">Billboards | Bigboards</h4>
                    <p className="content">
                      <i className="fa fa-money" /> Po dogovoru <br />
                      <i
                        className="fa fa-map-marker"
                        aria-hidden="true"
                      ></i>{" "}
                      Split i okolica, Šibenik i okolica, Zadar i okolica,
                      <br />
                      Rijeka i okolica, Dubrovnik i okolica, Dalmatinska Zagora,
                      Dalmatinski otoci <br />
                      <i
                        className="fa fa-plus-circle"
                        aria-hidden="true"
                        style={{ color: "white" }}
                      >
                        &nbsp; Prikaži više
                      </i>
                    </p>

                    <div className="list-price-meta">
                      <ul className="tp-list-meta d-inline-block">
                        <li>
                          180+ Oglasnih površina <br />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-6">
              <Link to="/wallscape">
                <div className="single-destinations-list style-four">
                  <div
                    className="blur-thumb"
                    style={{
                      backgroundImage:
                        "url(https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/15.png?alt=media&token=14d02380-e4ea-4724-be55-cd1aeab101b9)",
                    }}
                  />
                  <div className="details">
                    <h4 className="title">
                      Oglasne površine na stambenim jedinicima | WallScape
                    </h4>
                    <p className="content">
                      <i className="fa fa-money" /> Po dogovoru <br />
                      <i
                        className="fa fa-map-marker"
                        aria-hidden="true"
                      ></i>{" "}
                      Split i okolica, Šibenik i okolica, Zadar i okolica,
                      <br />
                      Rijeka i okolica, Dubrovnik i okolica, Dalmatinska Zagora
                      <br />
                      <i
                        className="fa fa-plus-circle"
                        aria-hidden="true"
                        style={{ color: "white" }}
                      >
                        &nbsp; Prikaži više
                      </i>
                    </p>
                    <div className="list-price-meta">
                      <ul className="tp-list-meta d-inline-block">
                        <li>
                          60+ Oglasnih površina <br />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-6">
              <Link to="/city-lights">
                <div className="single-destinations-list style-four">
                  <div
                    className="blur-thumb"
                    style={{
                      backgroundImage:
                        "url(https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/14.png?alt=media&token=e0a950e4-7428-478a-b27a-631edf6eb35a)",
                    }}
                  />
                  <div className="details">
                    <h4 className="title">
                      Oglasne površine na frekventnim pješačkim zonama | plaže{" "}
                    </h4>
                    <p className="content">
                      <i className="fa fa-money" /> Po dogovoru <br />
                      <i
                        className="fa fa-map-marker"
                        aria-hidden="true"
                      ></i>{" "}
                      Split i okolica, Dalmatinski otoci
                      <br />
                      <i
                        className="fa fa-plus-circle"
                        aria-hidden="true"
                        style={{ color: "white" }}
                      >
                        &nbsp; Prikaži više
                      </i>
                      <br />
                    </p>
                    <div className="list-price-meta">
                      <ul className="tp-list-meta d-inline-block">
                        <li>
                          120+ Oglasnih površina <br />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-6">
              <Link to="/nadvoznjaci">
                <div className="single-destinations-list style-four">
                  <div
                    className="blur-thumb"
                    style={{
                      backgroundImage:
                        "url(https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/16.png?alt=media&token=1e9a5c2a-41b9-4eeb-a151-8b0e437a4a53)",
                    }}
                  />
                  <div className="details">
                    <h4 className="title">
                      Gradski nadvožnjaci, nathodnici i tuneli
                    </h4>
                    <p className="content">
                      <i className="fa fa-money" /> Po dogovoru <br />
                      <i
                        className="fa fa-map-marker"
                        aria-hidden="true"
                      ></i>{" "}
                      Split i okolica, Rijeka i okolica
                      <br />
                      <i
                        className="fa fa-plus-circle"
                        aria-hidden="true"
                        style={{ color: "white" }}
                      >
                        &nbsp; Prikaži više
                      </i>
                    </p>
                    <div className="list-price-meta">
                      <ul className="tp-list-meta d-inline-block">
                        <li>
                          30+ Oglasnih površina <br />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Formati;
