import React from "react";

const DodatneUsluge = () => {
  return (
    <div className="blog-list-area pd-top-50 go-top">
      <div className="container pd-bottom-120">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center">
              <h2 className="title">Sve na jednom mjestu</h2>
              <p>
                Prokurator d.o.o. ne samo da svojim klijentima nudi najam
                reklamnih površina, već i pruža usluge grafičkog dizajna,
                printanja reklama kao i dodatne usluge. Bez obzira na vaše
                potrebe, naš stručni tim će se pobrinuti za sve detalje i
                osigurati vrhunsku kvalitetu usluga. Vaše
                reklame će biti izrađene stručno i kreativno, te će privući
                pažnju vaše publike i osigurati uspjeh vaše kampanje.
                <br />
                <b>
                  Zaželite reklamu, smislite okvirnu ideju, a mi ćemo se pobrinuti za sve
                  ostalo 
                </b>
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="single-destinations-list style-four">
              <div
                className="blur-thumb"
                style={{
                  backgroundImage:
                    "url(https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F28.png?alt=media&token=ff5d4366-d62d-4ebf-a963-7fde477ddabc)",
                }}
              />
              <div className="details">
                <h4 className="title">Grafički dizajn</h4>
                <p className="content">
                  Uz najam reklamnih površina mi se možemo pobrinuti
                  <br /> i za grafički dizajn Vaše reklame
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="single-destinations-list style-four">
              <div
                className="blur-thumb"
                style={{
                  backgroundImage:
                    "url(https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F29.png?alt=media&token=a8eb9fd6-3a57-455a-a354-9a582eda27a8)",
                }}
              />
              <div className="details">
                <h4 className="title">Printanje</h4>
                <p className="content">
                  Našim klijentima osiguravamo i mogućnost printanja <br />
                  reklama za sve vrste i dimenzije reklamnih površina
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="single-destinations-list style-four">
              <div
                className="blur-thumb"
                style={{
                  backgroundImage:
                    "url(https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F36.png?alt=media&token=5be1f634-235d-4bc2-a0ef-322e10f538a2)",
                }}
              />
              <div className="details">
                <h4 className="title">Izrada reklamnih panoa | PUTOKAZA</h4>
                <p className="content">
                  Po potrebi, uz postojeće reklamne površine možemo <br />{" "}
                  izraditi i dodatne reklamne površine po Vašoj želji.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="single-destinations-list style-four">
              <div
                className="blur-thumb"
                style={{
                  backgroundImage:
                    "url(https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F37.png?alt=media&token=350faaa9-8c1a-4b1c-b32a-b6db8d9521bf)",
                }}
              />
              <div className="details">
                <h4 className="title">Montaža reklamnih panoa</h4>
                <p className="content">
                  U najkraćem roku naši montažeri brzo i sigurno, <br /> po svim
                  pravilima struke montiraju Vaše reklame <br />
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="single-destinations-list style-four">
              <div
                className="blur-thumb"
                style={{
                  backgroundImage:
                    "url(https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F39.png?alt=media&token=0cf01fcb-9a05-42c0-bb9f-a7c8c3a1e936)",
                }}
              />
              <div className="details">
                <h4 className="title">Lijepljenje reklamne folije</h4>
                <p className="content">
                  Lijepljenje reklamnih folija na izloge, <br />
                  toteme, razne staklene površine.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="single-destinations-list style-four">
              <div
                className="blur-thumb"
                style={{
                  backgroundImage:
                    "url(https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F38.png?alt=media&token=159c6295-0ff9-40bc-beba-601a7b894b96)",
                }}
              />
              <div className="details">
                <h4 className="title">Razne druge usluge</h4>
                <p className="content">
                  Za sve želje i upite o uslugama koje nisu navede <br />
                  kontaktiraje naš tim i budite sigurni da ćemo napraviti
                  <br /> sve što je u našoj moći da ostvarimo Vašu želju.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DodatneUsluge;
