import React from "react";
import { Link } from "react-router-dom";
//import Map from "./map";

const MapaLokacija = () => {
  return (
    <div>
      <div className="destinations-details-page mg-top--70">
        <div className="container">
          {/* destinations-details-main-slider start */}
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="destinations-details-main-slider-wrap">
                <div className="destinations-details-main-slider">
                  <div className="d-details-main-slider-item">
                    <div style={{ height: "100vh", width: "100%" }}>
                      {/* <Map /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* location-details start */}
          <div className="location-details">
            <h4 className="single-page-small-title">Osnovne informacije</h4>
            <div className="row">
              <div className="col-lg-7">
                <div className="location-details-table">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="title">Država</td>
                        <td>Hrvatska</td>
                      </tr>
                      <tr>
                        <td className="title">
                          Ukupan broj Billboard-a i Bigboard-a
                        </td>
                        <td>180+</td>
                      </tr>
                      <tr>
                        <td className="title">
                          Ukupan broj oglasnih površina na plažama i drugim
                          pješačkim lokacijama
                        </td>
                        <td>120+</td>
                      </tr>
                      <tr>
                        <td className="title">Ukupan broj WallScape-a</td>
                        <td>60+</td>
                      </tr>
                      <tr>
                        <td className="title">
                          Ukupan broj Gradskih nadvožnjaka, nathodnika i tunela
                        </td>
                        <td>30+</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="location-details-map">
                  <p>
                    Za sve informacije o dostupnosti, cijeni i ostalo stupite u
                    kontakt s našim timom.
                  </p>
                  <Link className="btn btn-yellow" to="/kontakt">
                    Kontaktirajte nas <i className="fa fa-paper-plane" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* location-details end */}
        </div>
      </div>
    </div>
  );
};

export default MapaLokacija;
