import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';

const CommingSoon = () => { 

  useEffect(() => {    
    const $ = window.$;    
    $(window).on('load', function () {
      var preLoder = $("#preloader");
      preLoder.fadeOut(0);

   });
   
  }, [])
  

    return ( 
    <div className="comming-soon-page" style={{backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/1.png?alt=media&token=29f90028-7b42-472f-865d-102dcc8502d9)'}}>
			  <div className="container">			    
			    <div className="row justify-content-center">
			      <div className="col-12 comming-soon-info-wrap text-center">
			        <div className="comming-soon-info m-auto">
			          <h2 className="comming-soon-title" style={{color:'black'}}>COMING SOON</h2>
			          <div className="comming-soon-wrap">			            
			          </div>
                <div className="btn-wrapper">
			            <p>Kontaktirajte nas direktno i saznajte lokacije</p>
			          </div>
			          <div className="btn-wrapper">
			            <Link className="btn btn-yellow" to="/kontakt">Kontaktirajte nas</Link>
			          </div>
			          <ul className="social-icon" style={{paddingTop:20}}>			            
			            <li>
			              <a className="instagram" href="https://instagram.com/prokurator_advertising?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram  " /></a>
			            </li>			           
			            <li>
			              <a className="linkedin" href="https://www.linkedin.com/company/prokurator-advertising/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin" /></a>
			            </li>
			          </ul>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>)
}


export default CommingSoon