import React, { useEffect } from "react";

const Footer_v1 = () => {
  useEffect(() => {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);
  }, []);

  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col col-lg-2" style={{paddingBottom:'30px'}}>
            <img
              src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/logo.png?alt=media&token=3b048868-42ee-4c9a-8c6e-0ff79cd51779"
              alt="logo-footer"
            />
          </div>
          <div className="col-md-auto">
            <div className="footer-widget widget">
              <div className="about_us_widget">
                <div className="widget-contact">
                  <h4 className="widget-title">PROKURATOR D.O.O.</h4>
                  <p>
                    <span>OIB: 10663543913</span>
                  </p>
                  <p className="location">
                    <span>MBS: 060278578</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-lg-2">
            <div className="footer-widget widget ">
              <div className="widget-contact">
                <h4 className="widget-title">Kontaktirajte nas</h4>
                <p>
                  <i className="fa fa-map-marker" />
                  <span>Solinska 49, 21000 Split</span>
                </p>
                <p className="location">
                  <i className="fa fa-envelope-o" />
                  <span>info@prokurator.hr</span>
                </p>
                <p className="telephone">
                  <i className="fa fa-phone base-color" />
                  <span>+385 (0)98 642 235</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-inner">
        <div className="copyright-text">
          <ul className="social-icon">
            <li>
              <a
                className="linkedin"
                href="https://www.linkedin.com/company/prokurator-advertising/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-linkedin  " />
              </a>
            </li>
            <li>
              <a
                className="pinterest"
                href="https://instagram.com/prokurator_advertising?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-instagram" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer_v1;
