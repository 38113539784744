import React, { Component } from "react";

class Team extends Component {
  render() {
    return (
      <div
        className="team-newslater-bg"
        style={{
          backgroundImage:
            "url(https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/1.png?alt=media&token=ec402250-d88e-473f-85ae-289bfa32f31f)",
          marginTop: "15px",
        }}
      >
        {/* team area end */}
        <div className="team-area ">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="section-title text-center">
                  <h2 className="title">Naš tim</h2>
                  <p>
                    Tijekom višegodišnjeg rada potičemo timski rad stavljajući
                    klijente i posao u prvi plan. Samim tim posebnu pozornost
                    posvećujemo timskom radu i aktivnostima izgradnje timova,
                    koji poglavito dolaze do izražaja prilikom poslovne
                    odgovornosti te različitih razvojnih projekata koji se
                    kontinuirano realiziraju.
                  </p>
                </div>
              </div>
            </div>
            {/*   <div className="row">
              <div className="col-lg-3 col-sm-6">
                <div className="single-team text-center">
                  <div className="thumb">
                    <img src={publicUrl + "assets/img/team/1.png"} alt="team" />
                  </div>
                  <h3 className="name">
                    <a href="#">Nikola Matić</a>
                  </h3>
                  <span>Vlasnik i direktor</span>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-team text-center">
                  <div className="thumb">
                    <img src={publicUrl + "assets/img/team/1.png"} alt="team" />
                  </div>
                  <h3 className="name">
                    <a href="#">Jelena Kasalo Novokmet</a>
                  </h3>
                  <span>Direktor prodaje</span>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-team text-center">
                  <div className="thumb">
                    <img src={publicUrl + "assets/img/team/1.png"} alt="team" />
                  </div>
                  <h3 className="name">
                    <a href="#">Ines Tudor</a>
                  </h3>
                  <span>Marketing Manager</span>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="single-team text-center">
                  <div className="thumb">
                    <img src={publicUrl + "assets/img/team/1.png"} alt="team" />
                  </div>
                  <h3 className="name">
                    <a href="#">Andrea Munjiza</a>
                  </h3>
                  <span>Marketing Manager</span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* team area end */}
      </div>
    );
  }
}

export default Team;
