import React from "react";
import PageHeader from "./global-components/page-header";

import Formati from "./section-components/formati";

import Footer from "./global-components/footer";

const ProizvodiUsluge = () => {
  return (
    <div>
      <PageHeader headertitle="Tour Details" />
      <div className="tour-details-area mg-top--70  pd-top-120 ">
        <Formati />
      </div>
      <Footer />
    </div>
  );
};

export default ProizvodiUsluge;
