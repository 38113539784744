import React from "react";

const Nadvoznjaci = () => {
  return (
    <div className="blog-list-area pd-top-120 go-top">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center">
              <h2 className="title">Gradski nadvožnjaci i nathodnici</h2>
              <p>
                Prokurator d.o.o. u posjedu ima preko 30 oglasnih površina na
                gradskim nadvožnjacima i nathodnicima.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F1.png?alt=media&token=8dc13931-c3ec-4b8e-9824-44f9ecb7d7f4"
                  alt="blog1"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F2.png?alt=media&token=740e34ed-5493-4a20-b970-674f7c90d519"
                  alt="blog2"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F3.png?alt=media&token=95f2f7ea-4608-4e13-936a-0bc668f676db"
                  alt="blog3"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F4.png?alt=media&token=c5cc9bfa-5c3d-44fa-89b4-ccb991e4bc52"
                  alt="blog4"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F5.png?alt=media&token=1b097ade-8adf-4c08-9210-3d24627b7b95"
                  alt="blog5"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F6.png?alt=media&token=e577aef3-6790-4ce1-b761-39c8909a933d"
                  alt="blog6"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F7.png?alt=media&token=f9d4059c-39fc-464d-aac7-7dfd5f12579f"
                  alt="blog7"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F8.png?alt=media&token=da45703a-38c4-447a-ad23-bf6e966914d8"
                  alt="blog8"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F9.png?alt=media&token=c56630f5-2291-4471-ad16-1b8513b2358c"
                  alt="blog9"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nadvoznjaci;
