import React, { Component } from "react";

class Page_header extends Component {
  render() {
    return (
      <div
        className="breadcrumb-area jarallax"
        style={{
          backgroundImage:
            "url(https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/32.png?alt=media&token=d4677c68-c363-4cff-96ed-ce5ecbe60fa7)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page_header;
