import React from "react";
import PageHeader from "./global-components/page-header";
import MapaLokacija from "./section-components/mapa-lokacija";

import Footer from "./global-components/footer";

const Lokacije = () => {
  return (
    <div>
      <PageHeader headertitle="Lokacije" />
      <MapaLokacija />
      <Footer />
    </div>
  );
};

export default Lokacije;
