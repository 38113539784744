import React, { Component } from "react";
import { Link } from "react-router-dom";

class Intro extends Component {
  render() {
    return (
      <div className="intro-area pd-top-80">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div
                className="single-intro wow animated fadeInUp"
                data-wow-duration="1.6s"
                data-wow-delay="0.3s"
              >
                <h4 className="intro-title">
                  <span className="intro-count">250+</span>
                  <Link className="intro-cat" to="/about" style={{marginLeft:'5px'}}>
                  Putokaza
                  </Link>
                </h4>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="single-intro wow animated fadeInUp"
                data-wow-duration="1.0s"
                data-wow-delay="0.2s"
              >
                <h4 className="intro-title">
                  <span className="intro-count">500+</span>
                  <Link className="intro-cat" to="/about">
                    Oglasnih površina
                  </Link>
                </h4>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="single-intro wow animated fadeInUp"
                data-wow-duration="1.6s"
                data-wow-delay="0.3s"
              >
                <h4 className="intro-title">
                  <span className="intro-count">120+</span>
                  <Link className="intro-cat" to="/about" style={{marginLeft:'15px'}}>
                   Ljetnih formata
                  </Link>
                </h4>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="single-intro wow animated fadeInUp"
                data-wow-duration="1.6s"
                data-wow-delay="0.3s"
              >
                <h4 className="intro-title">
                  <span className="intro-count">110+</span>
                  <Link className="intro-cat" to="/about" style={{marginLeft:'20px'}}>
                  Naselja i Gradova
                  </Link>
                </h4>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="single-intro wow animated fadeInUp"
                data-wow-duration="1.6s"
                data-wow-delay="0.3s"
              >
                <h4 className="intro-title">
                  <span className="intro-count">16</span>
                  <Link className="intro-cat" to="/about" style={{marginLeft:'60px'}}>
                  Županija
                  </Link>
                </h4>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="single-intro wow animated fadeInUp"
                data-wow-duration="1.6s"
                data-wow-delay="0.3s"
              >
                <h4 className="intro-title">
                  <span className="intro-count" >7</span>
                  <Link className="intro-cat" to="/about" style={{marginLeft:'77px'}}>
                  Otoka
                  </Link>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;
