import React from "react";
import PageHeader from "./global-components/page-header";
import DodatneUsluge from "./section-components/dodatne-usluge";

import Footer from "./global-components/footer";

const Usluge = () => {
  return (
    <div>
      <PageHeader headertitle="Lokacije" />
      <DodatneUsluge />
      <Footer />
    </div>
  );
};

export default Usluge;
