import React, { Component } from "react";

class Info extends Component {
  render() {
    return (
      <div className="blog-list-area  go-top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="single-blog">
                <div className="thumb">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/3.png?alt=media&token=fc9a75fc-e69e-4845-8a8c-12e854b55652"
                    alt="info1"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-blog">
                <div className="thumb">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/4.png?alt=media&token=3d983944-89fb-4f63-8fb7-83fff6c21470"
                    alt="info2"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-blog">
                <div className="thumb">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/5.png?alt=media&token=fca897ce-23d7-4790-be62-747765d32323"
                    alt="info3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Info;
