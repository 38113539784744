import React from "react";
import PageHeader from "./global-components/page-header";
import BillBoard from "./section-components/billboards";

import Footer from "./global-components/footer";

const BillBoards = () => {
  return (
    <div>
      <PageHeader headertitle="Lokacije" />
      <BillBoard />
      <Footer />
    </div>
  );
};

export default BillBoards;
