import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomeV1 from "./components/home-v1";
import AboutUs from "./components/about";
import Lokacije from "./components/lokacije";
import Contact from "./components/contact";
import CommingSoon from "./components/comming-soon";
import Clients from "./components/clients";
import Nadvoznjaci from "./components/nadvoznjaci";
import Zgrade from "./components/zgrade";
import BillBoards from "./components/billboards";
import Pjesacka from "./components/pjesacka";
import Dodatne from "./components/usluge";
import ProizvodiUsluge from "./components/proizvodiUsluge";
import Navbar from "./components/global-components/navbar";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <div>
          <Navbar />
          <Switch>
            <Route exact path="/" component={HomeV1} />
            <Route path="/o-nama" component={AboutUs} />
            <Route path="/oglasne-povrsine" component={ProizvodiUsluge} />
            <Route path="/klijenti" component={Clients} />
            <Route path="/lokacije" component={Lokacije} />
            <Route path="/kontakt" component={Contact} />
            <Route path="/comming-soon" component={CommingSoon} />
            <Route
              path="/nadvoznjaci"
              component={Nadvoznjaci}
            />
            <Route path="/wallscape" component={Zgrade} />
            <Route
              path="/billboards-bigboards"
              component={BillBoards}
            />
            <Route path="/city-lights" component={Pjesacka} />
            <Route path="/dodatne-usluge" component={Dodatne} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById("root"));
