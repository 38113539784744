import React from "react";

const cityLights = () => {
  return (
    <div className="blog-list-area pd-top-120 go-top">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center">
              <h2 className="title">
                Oglasne površine na frekventnim pješačkim zonama | plaže
              </h2>
              <p>
                Prokurator d.o.o. u posjedu ima različite formate
                <br /> oglašavanja u pješačkim zonama.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F30.png?alt=media&token=65139bfb-fda2-45ab-9b9a-2d5b31516ded"
                  alt="blog1"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F31.png?alt=media&token=41e5d2ea-1e45-4529-b306-97f5c47f05f7"
                  alt="blog2"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F32.png?alt=media&token=5b928f8a-3f9f-4b81-bce3-9e0d42bd1f0a"
                  alt="blog3"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F33.png?alt=media&token=ea5ccd6e-392f-4047-a2b3-9a7493950d45"
                  alt="blog4"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F34.png?alt=media&token=320a5d05-71c1-4177-b4eb-c55f6cc73866"
                  alt="blog5"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F35.png?alt=media&token=9130e58c-ae1a-4eba-a692-21850e3df1e1"
                  alt="blog6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default cityLights;
