import React from "react";
import Banner from "./section-components/banner";
import Video from "./section-components/video";
import Intro from "./section-components/intro";
import Footer from "./global-components/footer";
import Formati from "./section-components/formati";
import Info from "./section-components/info";

const Home_V1 = () => {
  return (
    <div>
      <Banner />
      <Intro />
      <Video />
      <Formati />
      <Info />
      <Footer />
    </div>
  );
};

export default Home_V1;
