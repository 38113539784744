import React from "react";

const Zgrade = () => {
  return (
    <div className="blog-list-area pd-top-120 go-top">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center">
              <h2 className="title">
                Oglasne površine na
                <br /> stambenim jedinicama | WallScape
              </h2>
              <p>
                Prokurator d.o.o. u posjedu ima preko 60 oglasnih površina
                različitih dimenzija na stambenim jedinicama.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F10.png?alt=media&token=9b90bd32-33d9-410e-9a43-78878a108ed3"
                  alt="blog1"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F11.png?alt=media&token=9ed501bf-69dc-4342-9c39-2de10e48040f"
                  alt="blog2"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F12.png?alt=media&token=ea7a678a-ad8c-4797-a3f7-c0e83ba3e229"
                  alt="blog3"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F13.png?alt=media&token=eb94968a-d62e-42b3-8650-04a7414145c5"
                  alt="blog4"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F14.png?alt=media&token=acf3bda6-fa4a-4205-a7d9-dc975ce10c41"
                  alt="blog5"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F15.png?alt=media&token=aadad8d2-7631-409d-9771-2ffa4a5b0b60"
                  alt="blog6"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F16.png?alt=media&token=b91cdbf7-0e00-4687-8469-f332578be5b4"
                  alt="blog7"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F17.png?alt=media&token=2d4b8e35-0a94-4e44-a8a6-410fe0309992"
                  alt="blog8"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F18.png?alt=media&token=f2287b71-3816-4197-b490-543cb20f26bc"
                  alt="blog9"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Zgrade;
