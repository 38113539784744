import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="navbar navbar-area navbar-expand-lg nav-style-01 go-top">
      <div className="container nav-container">
        <div className="responsive-mobile-menu">
          <div className="mobile-logo">
            <Link to="/">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/sticky-logo.png?alt=media&token=77c77192-52ff-43f7-9c93-f0a08503dab9"
                alt="Prokurator"
              />
            </Link>
          </div>
          <button
            className="navbar-toggler float-right"
            type="button"
            data-toggle="collapse"
            data-target="#tp_main_menu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggle-icon">
              <span className="line" />
              <span className="line" />
              <span className="line" />
            </span>
          </button>
          <div className="nav-right-content">
            <ul className="pl-0">
              <li
                className="notification"
                style={{ backgroundColor: "red", borderRadius: "5px" }}
              >
                <Link to="/kontakt">
                  <i className="fa fa-paper-plane" style={{ color: "white" }} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="collapse navbar-collapse" id="tp_main_menu">
          <div className="logo-wrapper desktop-logo">
            <Link to="/" className="main-logo">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/sticky-logo-removebg-preview.png?alt=media&token=5ecd1bfa-b94f-4398-b03b-d4ff6e240865"
                alt="logo"
              />
            </Link>
            <Link to="/" className="sticky-logo">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/sticky-logo.png?alt=media&token=77c77192-52ff-43f7-9c93-f0a08503dab9"
                alt="sticky-logo-2"
              />
            </Link>
          </div>
          <ul className="navbar-nav">
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <Link to="/">
                <i className="fa fa-home" style={{ fontSize: 20 }} />
              </Link>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <Link to="/o-nama" >O nama</Link>
            </li>
            <li className="menu-item-has-children">
              <a href="/">
                <label>Vanjsko oglašavanje</label>
              </a>
              <ul className="sub-menu">
                <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <Link to="/oglasne-povrsine">Formati</Link>
                </li>
                <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <Link to="/dodatne-usluge">Dodatne usluge</Link>
                </li>
              </ul>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <Link to="/klijenti">Klijenti</Link>
            </li>
            <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
              <Link to="/comming-soon">Lokacije</Link>
            </li>
          </ul>
        </div>
        <div className="nav-right-content">
          <ul>
            <li>
              <Link className="btn btn-yellow" to="/kontakt">
                Kontaktirajte nas <i className="fa fa-paper-plane" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
