import React from "react";

const Klijenti = () => {
  return (
    <div className="gallery-area " style={{ paddingTop: "50px" }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center">
              <h2 className="title">
                Svoj uspjeh mjerimo zadovoljstvom naših klijenata.
              </h2>
              <p>
                Sa svim našim klijentima njegujemo partnerski i iskren odnos
                kojem smo maksimalno posvećeni tijekom cijele suradnje.
              </p>
            </div>
          </div>
        </div>
        {/* Gallery area start */}
        <div className="ads-area pd-top-10">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-9">
                <div className="ads-thumb">
                  <div className="thumb">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/klijenti_novi.png?alt=media&token=3b1c35df-57ca-4c7b-b2f5-7f7a2983a896"
                      alt="info1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <div className="ads-thumb">
                  <div className="thumb">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/klijenti3%20(3).png?alt=media&token=b584c0ee-ad06-4450-ba47-ff3abff24315"
                      alt="info1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <div className="ads-thumb">
                  <div className="thumb">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/klijenti2%20(2).png?alt=media&token=76ee39f8-5183-4b2a-adc7-f0ad072a41b2"
                      alt="info1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <div className="ads-thumb">
                  <div className="thumb">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/klijenti4.png?alt=media&token=e531471a-4a9f-48d8-afe5-599883d3b212"
                      alt="info1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <div className="ads-thumb">
                  <div className="thumb">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/klijenti5%20(2).png?alt=media&token=e84ea4fa-8c99-4bbf-8759-b269d08bccf0"
                      alt="info1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <div className="ads-thumb">
                  <div className="thumb">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/klijenti6.png?alt=media&token=b5dbf759-47db-46ca-b457-725cfdd1e689"
                      alt="info1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <div className="ads-thumb">
                  <div className="thumb">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/klijenti7%20(2).png?alt=media&token=b7bc9e65-bc4f-448a-b9b5-e8ba60462162"
                      alt="info1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <div className="ads-thumb">
                  <div className="thumb">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/klijenti8.png?alt=media&token=03536738-6c8c-4ff3-80ec-492caece5569"
                      alt="info1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <div className="ads-thumb">
                  <div className="thumb">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/klijenti9.png?alt=media&token=4422cd60-cda5-4be3-b966-f822342089eb"
                      alt="info1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <div className="ads-thumb">
                  <div className="thumb">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/klijenti10.png?alt=media&token=e8664b99-3ad8-42c1-9c21-b1a41afc4d06"
                      alt="info1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <div className="ads-thumb">
                  <div className="thumb">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/klijenti1.png?alt=media&token=32425e91-8f21-4722-92d3-4eac8af4ea94"
                      alt="info1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <div className="ads-thumb">
                  <div className="thumb">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/klijenti11.png?alt=media&token=5f850180-21c3-4014-86e1-078d47446288"
                      alt="info1"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-9">
                <div className="ads-thumb">
                  <div className="thumb">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/klijenti12%20(2).png?alt=media&token=2dd39f5d-f2f0-499b-bf24-16414f817a5c"
                      alt="info1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Klijenti;
