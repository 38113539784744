import React from "react";

const About = () => {
  return (
    <div className="about-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 align-self-center">
            <div className="section-title mb-lg-0">
              <h2 className="title">
                Povijest <br /> Prokuratora
              </h2>
              <p>Tvrtka Prokurator d.o.o. osnovana je 02. veljače 2012 godine
                  na trgovačkom sudu u Splitu. Sjedište tvrtke od njenog osnivanja do danas je grad Split.
                  Tvrtka se je od 2012 godine do danas postupno širila te sada broji 9 izravnih
                  zaposlenika te 15 neizravnih koji se svakodnevno trude pronaći i osigurati našim dugogodišnjim klijentima
                  lokacije na najprometnijim ulicama diljem Hrvatske. Naša ponuda lokacija za vanjsko oglašavanje sastoji se
                  od 500+ oglasnih površina diljem Hrvatske i uključuje statične i digitalne plohe formata bigboard, 
                  billboard, wallscape, putokazi i razne druge formate.</p>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-2">
            <div className="thumb about-section-right-thumb">
              <img
                src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/9.png?alt=media&token=1367a68a-30c9-4a65-a688-500fe8c79ce3"
                alt="img"
              />
              <img
                className="about-absolute-thumb"
                src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/10.png?alt=media&token=ee5d1c28-644b-415c-beec-53bae47cceca"
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
