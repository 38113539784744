import React from "react";
import PageHeader from "./global-components/page-header";
import CityLights from "./section-components/cityLights";

import Footer from "./global-components/footer";

const Pjesacka = () => {
  return (
    <div>
      <PageHeader headertitle="Lokacije" />
      <CityLights />
      <Footer />
    </div>
  );
};

export default Pjesacka;
