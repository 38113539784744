import React from "react";

const billboards = () => {
  return (
    <div className="blog-list-area pd-top-120 go-top">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-7">
            <div className="section-title text-center">
              <h2 className="title">Billboards</h2>
              <p>
                Prokurator d.o.o. u posjedu ima preko 180 billboarda i bigboarda
                koje se <br />
                nalaze na najprometnijim lokacijama diljem Hrvatske.
              </p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2Foutdoor-billboard-reklama-split.png?alt=media&token=b239af77-cf6a-4dd0-8586-bd77dc989584"
                  alt="Outdoor advertising vanjska reklama na prometnoj ulici u Splitu"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F20.png?alt=media&token=7740905d-502e-4cd1-b5f3-4bce5997e8f0"
                  alt="Outdoor advertising vanjska reklama veličine billboard koja se nalazi na lokaciji u Rijeci"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F21.png?alt=media&token=2695928d-9214-4745-8908-c9488b4dd5a7"
                  alt="blog3"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F22.png?alt=media&token=9695d988-f40b-44ff-a065-50b487c82118"
                  alt="blog4"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F23.png?alt=media&token=496e4543-5d4d-4b46-872e-70d9fb0d78fc"
                  alt="blog5"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F24.png?alt=media&token=608c958e-0a16-41e4-8129-33776478ff32"
                  alt="blog6"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F25.png?alt=media&token=c6b5a551-aedd-4829-a35d-75d5639cd232"
                  alt="blog7"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F26.png?alt=media&token=b6868e1a-e185-447e-93a6-dcc9dc32a427"
                  alt="blog8"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-blog">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/povrsine%2F27.png?alt=media&token=47d8611c-395d-4ea1-a3c1-e2fd690e397a"
                  alt="blog9"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default billboards;
