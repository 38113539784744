import React, { Component } from "react";

class Video extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div
        className="video-area tp-video-area"
        style={{
          backgroundImage: "url(" + publicUrl + "assets/img/bg/20.png)",
          paddingTop: "50px",
          paddingBottom: "80px",
        }}
      >
        <div className="container go-top">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6 align-self-center wow animated fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <div className="section-title mb-lg-0 mb-4 text-center text-lg-left">
                <h2 className="title">
                  Zašto odabrati <br /> vanjsko oglašavanje ?
                </h2>
                <p>
                  Vanjsko (outdoor) oglašavanje danas je jedno od
                  najpopularnijih načina prenošenja reklamne poruke. Biti stalno
                  prisutan iznimno je važno kako bi se publiku konstantno
                  podsjećalo na prisutnost oglašavane tvrtke na tržištu i tako
                  stvaralo povjerenje.
                </p>
                <hr />
                <p>
                  Spontano zapažanje oglasne poruke velika je prednost vanjskih
                  oglasa. Visoka frekventnost medija i dostupnost oglasa 24 sata
                  dnevno, 7 dana u tjednu osiguravaju da poruka bude apsorbirana
                  i usvojena kroz vrijeme.
                </p>
                <hr />
                <p>
                  Uporno pojavljivanje poruke u kontekstu vanjskog oglašavanja
                  kroz produženi period vremena uzrokovat će visoki nivo svjesti
                  tržišta.
                </p>
              </div>
            </div>
            <div
              className="col-xl-5 col-lg-6 offset-xl-1 wow animated fadeInLeft"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <div className="video-popup-wrap">
                <div className="thumb">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/video1.png?alt=media&token=d71145a9-0d9d-4907-8054-5c42e010077d"
                    alt="video"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Video;
