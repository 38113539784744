import React from "react";
import PageHeader from "./global-components/page-header";
import Klijenti from "./section-components/klijenti";
import Footer from "./global-components/footer";

const GalleryDetailsPage = () => {
  return (
    <div>
      <PageHeader headertitle="Gallery" />
      <Klijenti />
      <Footer />
    </div>
  );
};

export default GalleryDetailsPage;
