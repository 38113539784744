import React from "react";
import PageHeader from "./global-components/page-header";
import Nadvoznjak from "./section-components/nadvoznjaci";

import Footer from "./global-components/footer";

const Nadvoznjaci = () => {
  return (
    <div>
      <PageHeader headertitle="Lokacije" />
      <Nadvoznjak />
      <Footer />
    </div>
  );
};

export default Nadvoznjaci;
