import React, { Component } from "react";

class Banner extends Component {
  render() {
    return (
      <div className="banner-area go-top">
        <div className="banner-slider">
          <div className="banner-slider-item banner-bg-2">
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-9 offset-xl-2 offset-lg-1">
                  <div className="row">
                    <div className="col-lg-9 col-sm-8">
                      <div className="banner-inner">
                        <p className="banner-cat s-animate-1">USLUGE</p>
                        <h2 className="banner-title s-animate-2">
                          Vanjsko
                          <br /> oglašavanje
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="banner-slider-item banner-bg-1">
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-9 offset-xl-2 offset-lg-1">
                  <div className="row">
                    <div className="col-lg-9 col-sm-8">
                      <div className="banner-inner">
                        <p className="banner-cat s-animate-1">USLUGE</p>
                        <h2 className="banner-title s-animate-2">
                          Grafički dizajn
                          <br /> & Print
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-social-meta">
          <div className="banner-slider-dots" />
          <ul className="social-icon">
            <li>
              <a
                className="instagram"
                href="https://instagram.com/prokurator_advertising?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-instagram  " />
              </a>
            </li>
            <li>
              <a
                className="linkedin"
                href="https://www.linkedin.com/company/prokurator-advertising/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-linkedin" />
              </a>
            </li>
          </ul>
        </div>
        <div className="container">
          <div className="banner-slider-controls">
            <div className="slider-nav tp-control-nav" />
            {/*slider-nav*/}
            <div className="tp-slider-extra slider-extra">
              <div className="text">
                <span className="first">01</span>
                <span className="devider">/</span>
                <span className="last" />
              </div>
            </div>
            {/*slider-extra*/}
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
