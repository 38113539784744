import React, { Component } from "react";

class IntroV3 extends Component {
  render() {
    return (
      <div className="intro-area " style={{ paddingTop: "50px" }}>
        <div className="container">
          <div className="section-title text-lg-center text-left">
            <h2 className="title">
              <span>Upoznajte</span> Prokurator
            </h2>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="single-intro style-two">
                <h4 className="intro-title">Misija</h4>
                <p>
                  Naša misija je zadovoljstvo svakog klijenta pružanjem brze,
                  jednostavne i efikasne usluge najma reklamnih površina na
                  najprometnijim lokacijama.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="single-intro style-two">
                <h4 className="intro-title">Vizija</h4>
                <p>
                  Koristeći sve resusre kojima posjedujemo te stalnim širenjem
                  ponude, prvenstveno lokacija , vizija firme je i dalje širiti
                  svoje poslovanje, kako na hrvatskom tržištu tako i van
                  granica.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IntroV3;
