import React from "react";
import PageHeader from "./global-components/page-header";
import Zgrada from "./section-components/zgrade";

import Footer from "./global-components/footer";

const Zgrade = () => {
  return (
    <div>
      <PageHeader headertitle="Lokacije" />
      <Zgrada />
      <Footer />
    </div>
  );
};

export default Zgrade;
