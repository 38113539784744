import React from "react";
import PageHeader from "./global-components/page-header";
import Contact from "./section-components/contact";

import Footer from "./global-components/footer";

const ContactPage = () => {
  return (
    <div>
      <PageHeader headertitle="Contact Us" />
      <Contact />
      <Footer />
    </div>
  );
};

export default ContactPage;
