import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [obavijest, setObavijest] = useState("");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_m8c2qjq",
        "template_r7ntz14",
        form.current,
        "IwcweyybR6uAzgP5U"
      )
      .then(
        (result) => {
          setObavijest(
            "Vaša poruka je uspješno primljena! Kontaktirat ćemo vas ubrzo."
          );
          document.getElementById("myForm").reset();

          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <div className="contact-area" style={{ paddingTop: "50px" }}>
        <div className="container">
          <div className="row justify-content-center"></div>
          <div className="row">
            <div className="col-xl-5 offset-xl-1 col-lg-6">
              <div className="thumb">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/prokurator-web.appspot.com/o/contact.png?alt=media&token=e8383461-c4dd-4358-b00e-3afce815aab2"
                  alt="img"
                />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <form
                className="tp-form-wrap"
                ref={form}
                onSubmit={sendEmail}
                id="myForm"
              >
                <div className="row">
                  <div className="col-md-6">
                    <label className="single-input-wrap style-two">
                      <span className="single-input-title">Ime i Prezime</span>
                      <input type="text" name="user_name" />
                    </label>
                  </div>
                  <div className="col-md-6">
                    <label className="single-input-wrap style-two">
                      <span className="single-input-title">
                        Vaš kontakt broj
                      </span>
                      <input type="text" name="user_number" />
                    </label>
                  </div>
                  <div className="col-md-6">
                    <label className="single-input-wrap style-two">
                      <span className="single-input-title">Firma</span>
                      <input type="text" name="user_company" />
                    </label>
                  </div>
                  <div className="col-lg-12">
                    <label className="single-input-wrap style-two">
                      <span className="single-input-title">
                        * Email (Email na koji ćemo Vas kontaktirati)
                      </span>
                      <input type="email" name="user_email" required />
                    </label>
                  </div>
                  <div className="col-lg-12">
                    <label className="single-input-wrap style-two">
                      <span className="single-input-title">* Poruka</span>
                      <textarea name="message" required />
                    </label>
                  </div>
                  <div className="col-lg-12">
                    <p
                      style={{
                        color: "#f00",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      {obavijest}
                    </p>
                  </div>
                  <div className="col-12">
                    <input
                      type="submit"
                      className="btn btn-yellow"
                      value="Send Message"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-info-area pd-top-120">
        <div className="container">
          <div className="row justify-content-center pd-bottom-100">
            <div className="col-xl-7 col-lg-8 order-lg-12">
              <iframe
                title="contact-map"
                className="contact-map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2892.8187673153943!2d16.475604315705688!3d43.52697186860159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13355dfa10b999eb%3A0x61a92be09104a884!2sProkurator%20d.o.o.!5e0!3m2!1shr!2shr!4v1660653554989!5m2!1shr!2shr"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
